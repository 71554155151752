import { Link } from 'gatsby';
import React, { useState } from 'react';
import Burger from '@src/components/burger';
import { usePageList as usePageListDI } from '@src/hooks/page-list';
import { useSiteMetadata as useSiteMetadataDI } from '@src/hooks/site-metadata';
import { trackEvent } from '@src/services/analytics';
import { PageData, SiteSiteMetadata } from '@types';
import styles from './header.module.scss';

type HeaderProps = {
  pageId?: string;
  titleHidden?: boolean;
  useSiteMetadata?: () => SiteSiteMetadata;
  usePageList?: () => PageData[];
};

const Header: React.FC<HeaderProps> = ({
  pageId,
  titleHidden,
  useSiteMetadata = useSiteMetadataDI,
  usePageList = usePageListDI,
}) => {
  const [headerOpened, setHeaderOpened] = useState(false);
  const { title, subtitle } = useSiteMetadata();
  const pages = usePageList();

  return (
    <header
      className={`${styles.hf} ${headerOpened ? styles.open : ''}  ${
        titleHidden ? '' : styles.titleVisible
      }`}
    >
      <div className={`${styles.heading}`}>
        <div className={styles.headingTitle}>
          <span className={styles.headingFirst}>{title}</span>
          <span className={styles.headingSecond}>{subtitle}</span>
        </div>
      </div>
      <nav className={styles.links} role="navigation">
        <div className={styles.linksButton}>
          <Burger
            clickHandler={() => {
              trackEvent('Header', headerOpened ? 'Close' : 'Open');
              setHeaderOpened(!headerOpened);
            }}
            active={headerOpened}
          />
        </div>
        <ul className={styles.linksList}>
          {pages.map(p => (
            <li key={p.id} className={styles.linksItem}>
              <Link
                to={p.path}
                className={`${styles.link} ${
                  styles[p.id] ? styles[p.id] : ''
                } ${pageId === p.id ? styles.active : ''}`}
                onClick={() => {
                  trackEvent('Header', 'Click', p.path);
                }}
              >
                {p.name}
              </Link>
            </li>
          ))}
        </ul>
        <Link
          to="/contact"
          className={styles.mobileContact}
          onClick={() => {
            trackEvent('Header', 'Contact');
          }}
        >
          Contact
        </Link>
      </nav>
    </header>
  );
};

Header.defaultProps = {
  titleHidden: false,
};

export default Header;
