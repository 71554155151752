import GatsbyImage from 'gatsby-image/withIEPolyfill';
import React from 'react';
import { usePageList } from '@src/hooks/page-list';
import styles from './splash.module.scss';

type SplashProps = {
  pageId?: string;
  full?: boolean;
};

const Splash: React.FC<SplashProps> = ({ pageId, full }) => {
  const pages = usePageList();
  const currentPageData = pages.find(p => p.id === pageId);
  const image = currentPageData?.image;

  return (
    <div className={styles.splash}>
      {currentPageData?.image && (
        <div className={`${styles.container} ${full ? styles.full : ''}`}>
          <GatsbyImage
            className={styles.picture}
            fluid={image}
            objectPosition="center top"
            fadeIn={false}
            loading={'eager'}
          />
        </div>
      )}
    </div>
  );
};

export default Splash;
