/* eslint-disable react/jsx-no-target-blank */

import { Link } from 'gatsby';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { SiteSiteMetadata } from '@types';
import { useSiteMetadata as useSiteMetadataDI } from '@src/hooks/site-metadata';
import { trackEvent } from '@src/services/analytics';
import styles from './footer.module.scss';

type FooterProps = {
  useSiteMetadata?: () => SiteSiteMetadata;
};

const Footer: React.FC<FooterProps> = ({
  useSiteMetadata = useSiteMetadataDI,
}) => {
  const { title, subtitle, copyright, copyrightLink } = useSiteMetadata();

  return (
    <footer className={styles.footer} role="contentinfo">
      <div className={styles.content}>
        <div className={styles.baseline}>
          <span className={styles.title}>{title}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>

        <div className={styles.cgf}>
          <Link
            to={'/cgf'}
            className={styles.cgfLink}
            onClick={() => {
              trackEvent('Footer', 'CGF');
            }}
          >
            CGF
          </Link>
        </div>
        <div className={styles.copyright}>
          {copyrightLink && (
            <OutboundLink
              href={copyrightLink}
              target="_blank"
              rel="noopener"
              className={styles.copyrightLink}
              eventCategory="Footer"
              eventAction="Click"
              eventLabel="Copyright"
            >
              {copyright}
            </OutboundLink>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
